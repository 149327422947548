body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.file-formats-text {
  color: #4A5568;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.click-upload-file {
  color: #4A5568;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.hover-btn:hover {
  background-color: #319795e5 !important;
}

.notification-block {
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 1px 0px rgba(45, 55, 72, 0.05),
    0px 4px 8px 0px rgba(45, 55, 72, 0.10);
}