.App {
  text-align: center;
}

.App-logo {
  width: 500px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Dashboard.scss */
.sidebar {
  transition: transform 0.3s ease-in-out;
}

.sidebar-open {
  transform: translateX(0);
}

.sidebar-closed {
  transform: translateX(-100%);
}

.my-sidebar {
  border: unset;
  height: 100%;
}

.my-drawer-body {
  margin: 0 !important;
  padding: 0 !important;
}

.my-content-body {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  display: flex;
  height: 100vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 0 0 5px #d9d9d9;
    border-radius: 4px;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    margin: 10px 0;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #adb5bd;
    cursor: pointer;
  }
}