.login-left-side {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.heading {
  color: #fff !important;
  /* component-heading-large/3xl */
  font-family: "Inter";
  font-size: 60px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 100% !important;
}

.sub-text {
  color: #e6fffa !important;
  /* text-lg/lineHeight-7/font-normal */
  font-family: "Inter";
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}

.login-container {
  font-family: "Inter" !important;
}

.form-heading {
  color: #171923;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}

.form-sub-heading {
  color: #4A5568;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.form-sub-headin-link {
  color: #319795;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}