.step-title {
    color: #171923;
    /* md/medium */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
}

.step-sub-title {
    color: #4A5568;
    /* md/normal */
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.step-one-desc-footnote {
    color: #4A5568;
    /* sm/normal */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
}

.upload-image-label {
    color: var(--gray-800, #232323);
    /* sm/medium */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.menu-list>div {
    width: 100%;
}

.dropdown-menu-item {
    margin-bottom: 2px;
}

.menu-item-head {
    color: #000;
    /* text-lg/lineHeight-7/font-medium */
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
}

.menu-item-desc {
    color: #000;
    /* text-sm/lineHeight-5/font-normal */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.step-headline-text {
    color: #000;
    font-size: 20px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
}

.step-two-footer-text {
    color: #697586;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.short-cv::placeholder {
    color: #8C8C8C;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.dropdown-sub-block>div {
    width: 100%;
    gap: 0 !important;
    min-width: fit-content !important;
}